@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  color: rgb(234, 233, 233);
  --tw-bg-opacity: 1;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity));
  /* color: black; */
  min-height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);

}

body {
  background-color: #eef4ee;
  color: rgb(47, 46, 46);
  font-variant-numeric: tabular-nums;
  /* 横幅文字を均等にする */
  -webkit-user-select: none; /* Safari, Chrome */
  -ms-user-select: none;     /* Internet Explorer/Edge */
  user-select: none;         /* 標準のブラウザ */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}